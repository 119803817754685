@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: #32A16E;
  --secondary: #f9fafa;
  --black: #282c3f;
  --light: #535665;
  --borderLight: #e9e9eb;
  --lighter: #ced4da;
  --angle: 45deg;
  --opacity: 0.5;
}

.rainbow {
  padding: 2rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;
  /* Paint an image in the border */
  border-image: conic-gradient(from 45deg,
      #32A16E 0deg 90deg,
      #ddd 90deg 180deg,
      #ddd 180deg 270deg,
      #32A16E 270deg 360deg) 1 stretch;
  border-image: conic-gradient(from var(--angle),
      #32A16E 0deg 90deg,
      #ddd 90deg 180deg,
      #ddd 180deg 270deg,
      #32A16E 270deg 360deg) 1 stretch;
  background: rgb(255 255 255 / 0.5);
  background: rgb(255 255 255 / var(--opacity));
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @-webkit-keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @-webkit-keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .rainbow {
    -webkit-animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
            animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }
}

body {
  background-color: #f9fafa;
}

.logoImage {
  height: 60px;
  max-width: 150px;
  margin-left: -10px;
}

.navbar-collapse {
  align-items: center;
  background-color: white;
  /* width: 100%; */
  padding: 0px 16px;
  margin: 0px -16px;
}

.modal.fade,
.modal-backdrop.show {
  z-index: 10000000 !important;
}

.navbarDiv {
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 2px -2px gray;
  height: 79px;
}

.brandNameDiv {
  /* padding: 0px 32px; */
  border-radius: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.brandDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.brandName {
  font-size: 24px;
  font-weight: 700;
}

.brandSubtitle {
  font-size: 12px;
  color: #f8f9fa;
  font-weight: 600;
  text-align: right;
  line-height: 0;
}

.numberHide::-webkit-outer-spin-button,
.numberHide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.infoSection {
  display: flex;
  flex-direction: row;
}

.activeNav {
  font-weight: 400;
  font-size: 22px;
}

.commonFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.commonFlex2 {
  display: flex;
  flex-direction: row;
  position: relative;
}

/*home page css*/
.centerScreen {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.topFixedType {
  position: fixed;
  background: #f3f5f7;
  width: calc(100% - 680px);
  margin-right: 289px;
  z-index: 11;
  padding: 16px;
  margin-top: -20px;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
  align-items: center;
}

.mainTop {
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to right,
      rgba(111, 111, 111, 0.4),
      rgba(0, 0, 0, 0.6));
  z-index: 1111;
  height: -webkit-fill-available;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topFixedInside {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 40px;
}

.secTitle {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
  padding: 4px 0;
}

.logoText {
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  line-height: 20px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  padding: 4px 10px;
  text-transform: uppercase;
  color: #32a16e;
}

/*order css*/
.orderContainer {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  /* background-color: #f9fafa; */
}

/*category*/
.categoryContainer {
  width: 300px;
  position: fixed;
  overflow-y: scroll;
  background-color: #ffffff;
  padding-left: 24px;
  margin-top: -30px;
  padding-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categoryContainer::-webkit-scrollbar,
.messageBody::-webkit-scrollbar,
.phoneCat::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.categoryContainer,
.messageBody {
  -ms-overflow-style: none;
}

.categoryDiv {
  padding: 8px 0px;
  margin-right: 16px;
  cursor: pointer;
}

.categoryTitle:hover {
  color: #000 !important;
  font-weight: 600;
  transition: font-weight 0.1s;
}

.categoryTitle {
  font-size: 20px;
  font-weight: 400;
  color: #676c76;
  margin-right: 16px;
  transition: font-weight 0.1s;
}

.categoryItemTitle {
  font-size: 22px;
  font-weight: 700;
  color: #2e3333;
}

.categoryItemLittle {
  font-size: 16px;
  font-weight: 400;
  color: #585c5c;
  margin-bottom: 20px;
}

.csItemRightAfter {
  flex: 1 1;
}

.imageContainer {
  margin-left: 8px;
}

.imageStyle {
  height: 80px;
  width: 100px;
  object-fit: cover
}

/*menu items*/
.itemContainer {
  flex: 1 1;
  padding-right: 16px;
  padding-left: 16px;
  margin-left: 300px;
  margin-right: 350px;
  /* background-color: #f9fafa; */
  /*width: calc(100% - 650px);*/
  /*width: 100%;*/
}

.itemName {
  font-family: "Open Sans", sans-serif;
  color: #2e3333;
  font-weight: 600;
  font-size: 18px;
  flex: inherit !important;
}

.itemPrice {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin-right: 16px;
  flex: 1 1;
}

.modItemPrice {
  color: #282c3f;
  font-weight: 600;
  font-size: 16px;
  flex: 1 1;
}

.menuRight {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.addtoCart {
  cursor: pointer;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  height: 32px;
  width: 32px;
  border-top-left-radius: 50px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin: 0 8px;
  position: absolute;
  right: -24px;
  bottom: -16px;
}

.pcdiv {
  padding-top: 100px;
}

.profileContainer2 {
  background-color: #d6d6d6;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url("https://storage.quickfood.io/images/AntiquePizza/margherita.jpg");
  background-size: cover;
  background-position: center;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.addtoVoucher {
  cursor: pointer;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  padding: 2px 8px;
  border-top-left-radius: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0 8px;
  position: absolute;
  right: -24px;
  bottom: -16px;
}

.banner {
  background-color: #fb5e67;
  background-image: url("https://www.transparenttextures.com/patterns/brushed-alum.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  width: 100%;
  padding: 16px;
  /* background-color: #fb5e67; */
  font-size: 20px;
  color: #fff;
  /* height: 150px; */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.5s linear;
  align-items: center;
}

.p10Menu {
  padding-top: 30px;
}

.itemDescription,
.itemDescription2 {
  font-size: 14px;
  color: #585c5c;
}

.singleItem,
.singleItem2 {
  padding: 16px;
  margin: 8px 0;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.addressBox {
  margin: 0px 0px 12px 0px;
  cursor: pointer;
  background-color: #f3f5f7;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border-radius: 8px;
}

.addressSingle {
  padding: 10px 16px;
}

.singleItem:hover {
  box-shadow: 0 22px 24px 0 #00000014;
}

.itemCatHeader {
  font-size: 20px;
  font-weight: 700;
  padding: 8px 0;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  transition: 0.5s margin-left;
}

.itemCatHeader:hover {
  margin-left: 16px;
}

.itemCatHeaderMini {
  font-size: 14px;
  font-weight: 700;
  color: #535665;
  margin: 8px 0;
  padding: 4px 0;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
}

.itemElements {
  margin-bottom: 16px;
  border-bottom: 1px solid #e9e9eb;
}

.itemElements:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.buttonPadding {
  padding: 16px 0;
  width: 100%;
}

.modItemDiv {
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  margin-left: 84px;
  align-items: center;
}

.modItemDiv:last-child {
  border-bottom: none !important;
}

.selectedSetMeal {
  background-color: #fff;
  padding: 8px;
  margin: 8px 0;
}

.activeSetMeal {
  color: #fff;
  transition: 0.5s color ease-out;
  cursor: pointer;
  text-align: center;
}

.activeSetMeal .itemDescription {
  color: #ccc;
}

.setMealItem {
  font-size: 12px;
  margin: 4px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
}

.setMealItem:hover {
  background-color: #fafafa;
}

.setMealItemSelect {
  color: rgb(40, 44, 63);
  font-weight: 700;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.setMealSubMenu {
  width: 30%;
  padding: 8px 4px;
  background-color: #ffffff;
}

.orderCount {
  height: 32px;
  width: 32px;
  border-bottom-left-radius: 35px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: 0 8px;
  position: absolute;
  right: -24px;
  top: -16px;
}

/*cart styles*/
.cartContainer {
  width: 350px;
  position: fixed;
  right: 0;
  top: 70px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.cartDelete {
  margin-left: auto;
  cursor: pointer;
}

.cartItemsContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  overflow-y: scroll;
  box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px;
}

.alertMessage {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: orange;
}

.imageText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
}

.cartItems {
  padding: 8px 0;
  border-bottom: 1px solid;
}

.cartItemTitle {
  font-size: 16px;
  font-weight: 400;
  flex: 1 1;
  color: #2e3333;
}

.cartModTitle {
  font-size: 14px;
  font-weight: 400;
  flex: 1 1;
  color: #333;
}

.cartModDiv {
  margin-bottom: 4px;
}

.cartModDiv:last-child {
  margin-bottom: 0;
}

.cartPriceTitle {
  font-size: 18px;
  flex: 1 1;
  text-align: right;
  color: #2e3333;
}

.cartCrossDiv {
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
}

.cartBottom {
  text-align: center;
  margin-top: 40px;
  height: 260px;
}

.cartButton {
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.cartItemCount {
  margin: 0px 8px;
  font-size: 18px;
  width: 50px;
  text-align: center;
  color: #2e3333;
}

.innerCart {
  display: flex;
  flex-direction: row;
  padding: 24px 16px 16px;
  align-items: center;
}

.cartPlus {
  width: 22px !important;
  height: 22px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid;
  cursor: pointer;
  /* color: #fff; */
  box-shadow: 1px 1px 0px 0px #b9b9b9;
}

.cartPlus:hover {
  transition: 0.2s background-color;
}

.cartRemove {
  width: 20px;
  cursor: pointer;
}

.cartRemove:hover {
  color: red;
  opacity: 0.4;
  transition: 0.2s color;
}

.emptyBasket {
  font-size: 16px;
  color: #aaa;
  padding: 4px 0;
}

.modItemText {
  font-size: 16px;
  color: #2e3333;
  padding: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.mainMod {
  border-bottom: 1px solid rgb(226, 226, 226);
}

.modItemTextCenter {
  font-size: 16px;
  color: #2e3333;
  padding: 4px 0;
  cursor: pointer;
}

/*order process*/
.orderProcessContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  /* background-color: #f9fafa; */
}

.processTitle {
  padding: 16px;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.processTitleDone {
  background-color: #fff;
  margin: 16px 0px;
}

.orderProcessBody {
  background-color: #fff;
  padding: 32px;
  margin: 0px 16px 16px 16px;
}

.processBox {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
}

.processTab {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.processTabSelect {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border-bottom: 2px solid;
}

.processText {
  color: #b7b7b7;
  font-weight: 600;
  font-size: 18px;
}

.orderSuccessContainer {
  height: 90%;
  background-color: #fff;
  background-image: url(/static/media/opbg.f52176f9.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 1 1;
}

.orderInfo {
  flex: 1 1;
  padding: 36px 0px;
  margin: 32px 366px 0 16px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
}

.summery {
  padding: 36px 0;
  margin: 16px 16px 0 0;
  position: fixed;
  right: 0;
  background: #ffffff;
  height: 100%;
  top: 26px;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
}

.zistText {
  color: #2e3333;
  font-size: 16px;
  margin-left: 8px;
  flex: 1 1
}

.zistText:first-letter {
  text-transform: uppercase;
}

.summeryInfo {
  width: 300px;
  margin: 0 16px;
  max-height: 80vh;
  overflow-y: scroll;
}

.summeryDiv {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
}

.summeryItem {
  font-size: 18px;
  flex: 1 1;
}

.summeryValue {
  font-size: 18px;
  margin-right: auto;
}

.summeyInfoText {
  font-size: 16px;
  font-weight: 600;
  color: grey;
}

.orderButtonDiv {
  text-align: center;
  width: 100%;
  margin: 8px 0px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.orderButtonDiv:hover {
  cursor: pointer;
}

.offerDecs {
  text-align: center;
  font-size: 12px;
}

.extraDiv {
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.extraDivText {
  font-size: 18px;
  color: #2e332e;
  font-weight: 700;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 0;
  flex: 1 1;
  align-items: center;
  text-align: left;
}

.orderProcessHeader {
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  /* background-color: #f9fafa; */
  align-items: center;
  padding: 0px 16px 0px 32px;
  margin-bottom: 16px;
  z-index: 1021;
}

.processInputR {
  flex: 1 1;
  padding: 8px 8px 8px 0px;
}

.processInputU {
  flex: 1 1;
  padding: 8px 0px;
}

.processInputL {
  flex: 1 1;
  padding: 8px 0px 8px 8px;
}

.processDetailsDiv {
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
}

.processDetailText {
  font-size: 16px;
  font-weight: 600;
  flex: 1 1;
  color: #b7b7b7;
}

.processButtons {
  text-align: right;
}

.confirmButton {
  margin: 16px 0;
  color: #fff;
  font-weight: 600;
}

.processDetailsButton {
  padding: 8px;
  font-size: 11px;
  color: #ffffff;
  border-radius: 6px;
  transition: transfrom 0.2s;
  -webkit-transition: transfrom 0.2s;
  margin-left: auto;
}

.processDetailsButton:hover {
  font-weight: 700;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.p4 {
  padding: 4px 0;
}

.processButtonText {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  margin-top: 4px;
  cursor: pointer;
  padding: 2px 8px;
  margin-left: auto;
}

.addressSelect {
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  color: #2e3333;
}

.selectionContainer {
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin: 16px 0;
  transition: height 0.2s;
}

.selectionContainer:hover {
  transition: height 0.2s;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

/*information page*/
.infoWrapper {
  height: 100%;
  width: 100%;
}

.infoContainer {
  background-color: #ffffff;
  background-size: cover;
  padding: 32px;
  display: flex;
  flex-direction: row;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
  margin: 24px 16px;
}

.infoDetailsDiv {
  align-items: center;
  padding: 4px;
}

.profileContainer {
  background-color: #d6d6d6;
  background-image: url(/static/media/profilebg.4061c93c.png);
  background-size: cover;
  padding: 32px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.infoImage {
  height: 120px;
  width: 120px;
  margin: 0 16px;
}

.faviconText {
  font-size: 72px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.infoTitle {
  font-size: 24px;
  font-weight: 700;
}

.infoSubTitle {
  font-size: 14px;
  font-weight: 700;
}

.infoDescription {
  font-size: 12px;
  color: #7e808c;
  font-weight: 600;
}

.infoContact {
  font-size: 22px;
  font-weight: 700;
  color: #2e3333;
}

.infoDetails {
  flex: 2 1;
}

.mapContainer {
  margin-left: 16px;
}

.infoMain {
  flex: 1 1;
}

/*offer component*/
.offerContainer {
  margin: 0px 16px;
}

.headerTitle {
  font-size: 24px;
  font-weight: 700;
  padding: 32px 0px;
}

.offerDetails {
  height: 100px;
  overflow-y: hidden;
}

.offerDetails::-webkit-scrollbar,
.summeryInfo::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.offerDetails,
.summeryInfo {
  -ms-overflow-style: none;
}

.offerDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 16px;
  cursor: pointer;
}

.hideShow {
  display: flex;
  width: 50px;
  height: 50px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px rgb(206, 212, 218);
  position: absolute;
  right: 0;
  right: 10px;
  font-size: 14px;
  font-weight: 700;
}

.offerImage {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.offerTitle {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  padding: 4px 0;
}

/*gallery*/
.itemImage {
  position: relative;
}

.galleryImage {
  position: relative;
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.modalImgTitle {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  padding: 16px;
}

.modalImgDescription {
  font-size: 14px;
  text-align: center;
  padding: 0px 16px;
  margin-bottom: 16px;
}

/*auth(signin/ signup)*/
.signinContainer {
  margin-top: 32px;
}

.authContainer {
  width: 450px;
  margin: 24px auto;
  background-color: #ffffff;
  background-size: cover;
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
}

.authTitle {
  font-size: 20px;
  text-align: center;
}

.mobileCode {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  margin-right: 16px;
  line-height: 1.5;
  border-radius: 0.3rem;
  border: 1px solid #ced4da;
}

.highlight {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.authLittle {
  font-size: 12px;
}

.buttonAuthDiv {
  position: relative;
}

.buttonAuth {
  position: absolute;
  right: -38px;
  top: -42px;
  background-color: #fff;
  width: 25px !important;
  height: 25px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid red;
}

.modalClose {
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: #fff;
  width: 32px !important;
  height: 32px;
  padding: 7px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 18%) 0px 2px 4px;
  cursor: pointer;
}

.resendButton {
  margin: 16px auto;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: no-drop;
}

.extraButtonAuth {
  position: absolute;
  right: 0px;
  top: 12px;
  background-color: #fff;
  width: 20px !important;
  height: 20px;
  padding: 3px;
  border-radius: 16px;
  border: 1px solid red;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  -webkit-animation-name: popAnim;
          animation-name: popAnim;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.overlayModal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1049;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  transition: 0.5s;
  -webkit-animation-name: popAnim;
          animation-name: popAnim;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.overlayContainer {
  position: relative;
  top: 25%;
  width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px;
  text-align: center;
  margin-top: 30px;
  background-color: #fff;
}

.overlayClose {
  position: absolute;
  right: 33px;
  top: 25px;
  background: white;
  padding: 5px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid red;
  cursor: pointer;
}

.overlayClose2 {
  background: white;
  padding: 1px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid red;
  cursor: pointer;
  margin-left: auto;
  margin-top: 16px;
  margin-right: 24px;
}

@-webkit-keyframes popAnim {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes popAnim {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
}

/*profile page*/
.profileBody {
  display: flex;
  flex-direction: column;
}

.profileDetailsDiv {
  text-align: center;
  margin: auto;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: #f8f9f9;
}

.innerProfileDetails {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileBodyDiv {
  padding: 16px;
}

.profileBodyItems {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.valueProfile {
  font-size: 18px;
  margin-top: 8px;
  color: #333;
  font-weight: 600;
}

.hudacss {
  flex: 1 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.bodyDetailsDiv {
  padding: 16px;
  text-align: center;
  margin: 16px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f8f9f9;
  border-radius: 8px;
}

.bodyDetailsButton {
  flex: 1 1;
  margin: 16px;
}

.categoryFloat {
  display: none;
}

.cartMobile {
  display: none;
}

.cartbackBtn {
  display: none;
}

/*all common hovers*/
.addtoCart:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cartItemsContainer::-webkit-scrollbar,
.mobileCatShow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.cartItemsContainer,
.mobileCatShow {
  -ms-overflow-style: none;
}

.cartCount {
  position: absolute;
  top: 3px;
  right: 20px;
  background: red;
  height: 20px;
  width: 20px;
  font-size: 12px;
  color: white;
  border-radius: 10px;
}

.chatCounter {
  position: absolute;
  top: 0px;
  right: -5px;
  background: red;
  height: 20px;
  width: 20px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.address_out_range {
  background-color: #ddd;
  cursor: no-drop;
  position: relative;
  padding: 8px;
}

.nameDiv,
.textBoxDiv {
  margin: 0 10px;
  display: flex;
  flex-direction: row;
}

.buttonDiv {
  margin: 0 10px;
  display: flex;
  flex-direction: row;
}

.iconButton {
  padding: 8px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid;
  cursor: pointer;
  box-shadow: 1px 1px 0px 0px #b9b9b9;
}

.iconButton:hover {
  background-color: #fff;
}

.textBoxProfile {
  margin: 0 8px;
}

.pickerBox {
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px 8px 0px 0px;
}

.pickerItem {
  background-color: #f8f9f9;
  padding: 8px;
  margin: 8px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.pickerItem:hover {
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.pickerLabel {
  font-size: 16px;
  color: #2e332e;
}

.pickerLabel:hover {
  font-weight: 600;
}

.otDiv {
  background-color: #ffffff;
  padding: 16px 0 !important;
  border: none;
}

.otDays {
  padding: 16px 0 !important;
  border-top: none !important;
  font-size: 14px;
  font-weight: 700;
  color: #2e3333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otSession {
  color: #aaa;
  font-size: 14px;
  font-weight: 700;
  border-top: none !important;
}

.otTime {
  color: #2e3333;
  font-size: 14px;
  font-weight: 700;
}

.orderCompleteImage {
  width: 400px;
  height: auto;
  margin: 24px auto;
}

.successContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

/*chat design*/
.header {
  position: fixed;
  left: 0;
  top: 72px;
  width: 100%;
  padding: 8px 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  /*height:50px;*/
  background-color: #fff;
  z-index: 11;
}

.chatIcon {
  height: 32px;
  width: 32px !important;
  border-radius: 8px;
  margin-right: 8px;
}

.messageBody {
  height: calc(100vh - 172px);
  margin-top: 125px;
}

.userChatDiv {
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
}

.restChatDiv {
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
  align-items: flex-start;
  justify-content: flex-end;
}

.userChatBox {
  padding: 16px;
  border-radius: 8px;
}

.userChatText {
  font-size: 14px;
  color: #fff;
}

.restChatBox {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
}

.restChatText {
  font-size: 14px;
  color: #333;
}

.textBox {
  background-color: #e6e6e6 !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  height: 50px;
}

.textLink {
  font-weight: 600;
  font-size: 14px;
  color: #2e3333;
}

.iconInfos {
  position: absolute;
  right: 48px;

}

.confirmButton {
  border: none;
}

.confirmButton:hover {
  background-color: #32A16E;
  opacity: 0.9;

}

.sendBtn {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  padding: 13px 32px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer2 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #2e3333;
  text-align: center;
  padding: 16px;
  font-size: 18px;
}

.footer3 {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #2e3333;
  text-align: center;
  padding: 16px;
  font-size: 18px;
  margin-bottom: 20px;
}

/*orderOnline mobile design*/

.bottomMessage {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  bottom: 0px;
  width: calc(100% - 680px);
}

.bottomText {
  font-size: 11px;
  text-align: center;
  padding: 2px 0px;
}

.onlyMobile {
  display: none;
}

.onlyDesktop {
  display: block;
}

.belowText {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.oor {
  font-size: 11px;
  position: absolute;
  right: 0;
  background: #f8d7da;
  color: #721c24;
  padding: 0px 4px;
  top: -3px;
}

@media (min-width: 1600px) {
  .screenWrapper {
    width: 80%;
    margin: 24px auto;
    max-width: 1600px;
  }

  .bottomText {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .banner {
    font-size: 16px;
  }

  .pcdiv {
    padding-top: 0;
  }

  .hideonPhone {
    display: none;
  }

  .banner-content {
    flex-direction: column;
  }

  .p10 {
    padding: 10px 0;
    font-size: 16px;
  }

  .orderButtonDiv {
    margin-bottom: 24px;
  }

  .screenWrapper {
    margin-bottom: 60px;
  }

  .belowText {
    align-items: flex-start;
  }

  .commonFlexMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
  }

  .commonFlexMobile h6 {
    text-align: left;
  }

  .cartBottom {
    height: auto;
    margin-top: 0;
  }

  .itemDescription {
    display: -webkit-box;
    /* max-width: 400px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .footerPadding {
    margin-bottom: 100px;
  }

  .topFixedType {
    width: 100%;
    margin-bottom: -16px;
    position: absolute;
  }

  .topFixedTypeNull {
    width: 170px;
    position: fixed;
    top: 18px;
    left: 45%;
    z-index: 1044;
    background: transparent;
    box-shadow: none;
    margin-left: -64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infoSection {
    flex-direction: column;
  }

  .phoneCatHead {
    position: fixed;
    background: #f8faff;
    width: 300px;
    padding: 0 16px;
    height: 40px;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    transition: width 0.2s;
  }

  .phoneCat {
    height: 400px;
    position: fixed;
    bottom: 0;
    width: 300px;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 auto;
    z-index: 1111;
  }

  .bottomMessage {
    width: 100%;
    top: 80px;
    margin-left: -16px;
    bottom: auto;
  }

  .faviconDiv {
    margin: 0px auto;
  }

  .middlePhone {
    justify-content: center;
  }

  .infoContainer {
    flex-direction: column;
    padding: 16px;
    margin: 16px;
  }

  .infoIcons {
    right: 16px;
  }

  .infoImage {
    margin: 0px auto;
    display: flex;
    flex: 1 1;
  }

  .infoTitle,
  .infoSubTitle,
  .infoContact {
    text-align: center;
  }

  /*order process*/
  .orderProcessContainer {
    flex-direction: column;
  }

  .orderInfo {
    margin: 24px 16px 0px 16px;
    padding: 36px 0px 16px;
  }

  .summery {
    padding: 8px 32px 0px 0px;
    margin: 16px 16px 24px 16px;
    position: initial;
    right: 0;
    height: auto;
  }

  .summeryInfo {
    width: 100%;
    height: auto;
    overflow-y: auto;
    max-height: 100%;
  }

  .address_free {
    background-color: #00dd1c;
  }

  .address_fees {
    background-color: #2e6da4;
  }

  .address_out_range {
    background-color: #ddd;
  }

  .profileContainer {
    flex-direction: column;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
  }

  .nameDiv {
    justify-content: center;
    align-items: center;
  }

  .textBoxDiv {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px 0;
  }

  .textBoxProfile {
    margin: 4px 0;
  }

  .buttonDiv {
    justify-content: center;
    align-items: center;
  }

  /*orderOnline mobile */
  .itemContainer {
    margin-bottom: 48px;
    margin-left: 0;
    margin-right: 0;
  }

  .categoryContainer {
    width: 0;
    /*transition: 0.2s width;*/
    padding-left: 0;
  }

  .cartContainer {
    display: none;
  }

  .mobileCatShow {
    margin-top: 32px;
    width: 250px;
    transition: 0.2s width;
    position: fixed;
    overflow-y: scroll;
  }

  .cartShowItemRight {
    margin-left: 250px;
    transition: 0.2s margin-left;
    overflow-x: hidden;
  }

  .csItemRightAfter {
    margin-left: 0;
    transition: 0.2s margin-left;
  }

  .cartMobile {
    height: 56px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ababc0;
    cursor: pointer;
    margin-left: -16px;
  }

  .cartbackBtn {
    display: block;
  }

  .cartTotalDiv {
    font-size: 16px;
    color: #fff;
    text-align: center;
    flex: 1 1;
  }

  .cartIcon {
    width: 100px;
    height: 56px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 16px;
  }

  .cartCount {
    position: absolute;
    top: 3px;
    right: 20px;
    height: 20px;
    /* padding: 5px; */
    width: 20px;
    font-size: 12px;
    color: white;
    border-radius: 10px;
  }

  .categoryFloat {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #32A16E;
    position: fixed;
    /* width: 100px; */
    height: 35px;
    border-radius: 50px;
    /* border: 1px solid #8e8e8e; */
    text-align: center;
    font-weight: 600;
    padding: 0px 8px;
    /* padding-top: 4px; */
    left: 48%;
    bottom: 10%;
    margin-left: -50px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .catClose {
    /*position:fixed;*/
    /*left:220px;*/
    cursor: pointer;
    margin-left: auto;
    /*transition: 0.2s all;*/
  }

  .mobileCart {
    width: 100%;
    height: 100%;
    z-index: 1111111;
    position: fixed;
    background: rgb(250, 250, 251);
    /* padding: 16px 0px 0px 16px;; */
    top: 0px;
    /*margin-top: 16px;*/
  }

  .bodyOf {
    overflow-y: hidden !important;
    /* background-color:green; */
  }

  .mobileCart>body {
    overflow-y: hidden !important;
  }

  .processText {
    font-size: 18px;
  }

  .homeContent {
    padding-left: 8px;
  }

  .mainTitle {
    font-size: 38px;
  }

  .bottomTitle {
    font-size: 16px;
  }

  .secTitle,
  .upTitle {
    font-size: 14px;
    /* text-align: left; */
  }

  .disableButton {
    margin: 16px 0;
    background-color: #aca5ae;
    color: #2d3032;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .nav-link {
    padding: 0px;
  }

  .logoImage {
    max-width: 100px;
    margin-left: 0;
    object-fit: contain;
  }

  .activeNav {
    text-align: right;
    padding: 8px 0;
    font-size: 16px;
  }

  .overlayClose2 {
    margin-right: 16px;
  }

  .logoText {
    font-size: 17px;
    line-height: 18px;
    padding: 4px 6px;
  }

  .brandName {
    font-size: 20px;
  }

  .screenWrapper {
    margin-top: 24px
  }

  .rainbow {
    flex-direction: column;
    text-align: center;
  }

  .onlyMobile {
    display: block;
  }

  .onlyDesktop {
    display: none;
  }

  h3 {
    font-size: 18px;
  }

  .notificationText {
    font-size: 11px;
  }

  .authContainer {
    width: 90%;
    /*margin: 16px;*/
  }

  /*proflie screen*/
  .profileBody,
  .bodyDetailsDiv {
    flex-direction: column;
  }

  .profileBodyItems {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .setMealSubMenu {
    width: 40%;
  }

  .itemCatHeader {
    font-size: 16px;
  }

  .processText {
    font-size: 16px;
  }

  .homeButton {
    /*margin: 8px 0;*/
    font-size: 14px;
    padding: 8px;
  }

  .mapContainer {
    margin-left: 0px;
    margin-top: 16px;
  }

  .modItemDiv {
    margin-left: 32px;
  }

  .itemName,
  .itemPrice {
    font-size: 16px !important;
    flex: 1 1;
  }

  .categoryFloat {
    bottom: 12%;
  }

  .cardViewPhone {
    min-width: 600px;
    margin: 0px auto;
    overflow: scroll !important;
  }

  .imageStyle {
    height: 70px;
    width: 90px;
    /* margin-left: -8px; */
  }

  .itemDescription {
    font-size: 12px !important;
    display: -webkit-box;
    /* max-width: 400px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 430px) {
  .logoImage {
    max-width: 80px;
  }

  .banner {
    font-size: 14px;
  }

  .upTitle {
    font-size: 14px;
  }

  .mainTitle {
    font-size: 28px;
  }

  .secTitle,
  .upTitle {
    font-size: 12px;
  }

  .orderCompleteImage {
    width: 250px !important;
  }

  .itemCatHeaderMini {
    font-size: 11px;
  }

  .processText {
    font-size: 13px;
  }

  .modItemDiv {
    margin-left: 16px;
  }

  .itemName,
  .itemPrice {
    font-size: 16px;
  }

  .categoryFloat {
    bottom: 14%;
  }

  .imageContainer {
    /* padding: 6px 10px; */
    /* height: 40px; */
    /* width: 40px; */
    /* margin-right: 8px; */
  }

  .brandNameDiv {
    padding: 0 8px;
  }

  .brandDiv {
    padding-top: 10px;
  }

  .brandName {
    font-size: 16px;
  }

  .brandSubtitle {
    font-size: 10px;
  }

  .orderProcessBody {
    padding: 0px 16px;
    margin: 16px 0px 0px 0px;
  }

  .alert-light {
    font-size: 12px;
  }

  .notificationText {
    font-size: 9px;
  }
}

@media screen and (max-width: 360px) {
  .p10 {
    font-size: 11px;
  }
}

@media screen and (min-width: 2000px) {
  .topFixedType {
    width: calc(70% - 680px);
  }

  .orderWrapper {
    width: 70%;
    margin: 0px auto;
    /*max-width: 1600px;*/
  }

  .bottomMessage {
    width: calc(68% - 650px);
  }
}
/* #root {
    display: flex;
    align-items: center;
  } */

/* body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  } */

form {
	/* width: 30vw; */
	width: 90%;
	align-self: center;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 24px;
	margin: 24px auto;
}

input {
	border-radius: 6px;
	margin-bottom: 6px;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	max-height: 44px;
	font-size: 16px;
	width: 100%;
	background: white;
	box-sizing: border-box;
}

.result-message {
	line-height: 22px;
	font-size: 16px;
}

.result-message a {
	color: rgb(89, 111, 214);
	font-weight: 600;
	text-decoration: none;
}

.hidden {
	display: none;
}

#card-error {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	margin-top: 12px;
	text-align: center;
}

#card-element {
	border-radius: 4px 4px 0 0;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	max-height: 44px;
	width: 100%;
	background: #f3f5f7;
	box-sizing: border-box;
}

#payment-request-button {
	margin-bottom: 32px;
}
.cardAdd {
	width: 100%;
	border: none;
	background: #32a16e;
	padding: 9px;
	color: #fff;
	border-radius: 0 0 4px 4px;
}
button:hover {
	-webkit-filter: contrast(115%);
	        filter: contrast(115%);
}

button:disabled {
	opacity: 0.5;
	cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
	border-radius: 50%;
}

.spinner {
	color: #ffffff;
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.spinner:before,
.spinner:after {
	position: absolute;
	content: "";
}

.spinner:before {
	width: 10.4px;
	height: 20.4px;
	background: undefined;
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
	width: 10.4px;
	height: 10.2px;
	background: undefined;
	border-radius: 0 10.2px 10.2px 0;
	top: -0.1px;
	left: 10.2px;
	-webkit-transform-origin: 0px 10.2px;
	transform-origin: 0px 10.2px;
	-webkit-animation: loading 2s infinite ease;
	animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 600px) {
	form {
		width: 95%;
	}
}

.itemSection{
    flex:1 1;
}
.itemBox{
    background-color: #F9F9F9;
    padding: 16px;
    margin: 16px 0;
    border: 2px solid #FFF;
    transition: 0.2s box-shadow;
}
.itemBoxSelect{
    background-color: #fff;
    padding: 16px;
    margin: 16px 0;
    border: 2px solid #32A16E;
    transition: 0.2s  box-shadow;
    border-radius: 8px;
    cursor: pointer;
}
.itemBox:hover{
    border: 1px solid #eee;
    cursor:pointer;
    box-shadow: 0 2px 2px 0 rgba(218,220,230,.6)
}
.itemName{
    font-size: 18px;
    color: #2e332e;;
    font-weight: 700;
    flex:1 1
}
.itemPrice{
    font-size: 18px;
    color:#333;
    font-weight: 600;
    margin-left: auto;
}
.itemDescription{
    font-size: 14px;
    color:#585c5c;
}
.tags{
    background-color: rgba(255,159,0,0.2);
    padding: 2px 4px;
    border-radius: 10px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.tagsText{
    font-size: 10px;
    color:#FF9F00;
    text-align: center;
}
.allergies{
    background-color: #C94B4B;
    padding: 2px 4px;
    border-radius: 4px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
}
.allergyText{
    font-size: 10px;
    color:#fff;
    text-align: center;
}
.addNumber{
    background-image: none;
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addBtn{
    background: linear-gradient(to right, #32A16E, #890503);
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addText{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

/*reser*/

.rvSelect{
    border-bottom:2px solid #32A16E;
    padding: 16px;
    margin: 8px 0;
    font-size: 24px;
    color:#32A16E;
    flex:1 1;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    transition: 0.2s box-shadow;

}
.rvNotSelect{
    border-bottom:2px solid #b7b7b7;
    padding: 16px;
    margin: 8px 0;
    font-size: 24px;
    color:#b7b7b7;
    font-weight: 700;
    flex:1 1;
    justify-content: center;
    align-items: center;
    transition: 0.2s box-shadow;
}
.rvSelect:hover, .rvNotSelect:hover{
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(218,220,230,.6)
}

.resLogo{
    height: 60px!important;
    width: 60px;
    object-fit: contain;
}
.resLogoDiv{
    padding: 16px 0px;
    margin-right: 16px;
}
.resSmall{
    font-size: 16px;
    font-weight: 600;
    color:#b7b7b7;
}
.resSmallIcon{
    font-size: 16px;
    font-weight: 600;
    color:#b7b7b7;
    margin-right: 4px;
}
.reservationContainer{
    /*padding: 0px;*/
    border: 1px solid #fff;
    padding: 16px;
    transition: .2s box-shadow;
    border-radius: 4px;

}
.reservationContainer:hover{
    border: 1px solid #ddd;
    transition:.2s box-shadow;
    box-shadow: 0 4px 4px 0 rgba(218,220,230,.6);
    cursor:pointer;
}
.resTitle{
    color:#b7b7b7;
    font-size: 18px;
    font-weight: 700;
    margin-right: 8px;
}
.resTitleSelect{
    color:#32A16E;
    font-size: 18px;
    font-weight: 700;
    margin-right: 8px;
    border-bottom: 1px solid #32A16E;
}
.reserButton{
    /*//background-image: linear-gradient(to right, #c94b4b , #4b134f);*/
    background:linear-gradient(to right, #32A16E, #890503);
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 48px;
    border-radius: 8px;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    transition: .2s box-shadow;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}
.reserProcessButton{
    /*//background-image: linear-gradient(to right, #c94b4b , #4b134f);*/
    background-color: #aca5ae;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 48px;
    border-radius: 8px;
    color: #2d3032;
    font-size: 16px;
    font-weight: 700;
    transition: .2s box-shadow;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}
.reserButton:hover{
    transition:.2s box-shadow;
    box-shadow: 0 4px 4px 0 rgba(218,220,230,.6);
    cursor:pointer;
}
.textBox{
    background-color: #e6e6e6 !important;
    color: #333333! important;
    font-size: 16px!important;
    font-weight: 700!important;
}
.commonFlexR{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 767px){
    .commonFlexR{
        flex-direction: row;
    }
    .rvSelect,.rvNotSelect {
        padding: 8px;
        margin: 4px 0;
        font-size: 16px;
    }
    .resTitle,.resTitleSelect{
        font-size: 16px;
    }
    .resSmall{
        font-size: 13px;
    }
}
