.itemSection{
    flex:1;
}
.itemBox{
    background-color: #F9F9F9;
    padding: 16px;
    margin: 16px 0;
    border: 2px solid #FFF;
    transition: 0.2s box-shadow;
}
.itemBoxSelect{
    background-color: #fff;
    padding: 16px;
    margin: 16px 0;
    border: 2px solid #32A16E;
    transition: 0.2s  box-shadow;
    border-radius: 8px;
    cursor: pointer;
}
.itemBox:hover{
    border: 1px solid #eee;
    cursor:pointer;
    box-shadow: 0 2px 2px 0 rgba(218,220,230,.6)
}
.itemName{
    font-size: 18px;
    color: #2e332e;;
    font-weight: 700;
    flex:1
}
.itemPrice{
    font-size: 18px;
    color:#333;
    font-weight: 600;
    margin-left: auto;
}
.itemDescription{
    font-size: 14px;
    color:#585c5c;
}
.tags{
    background-color: rgba(255,159,0,0.2);
    padding: 2px 4px;
    border-radius: 10px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.tagsText{
    font-size: 10px;
    color:#FF9F00;
    text-align: center;
}
.allergies{
    background-color: #C94B4B;
    padding: 2px 4px;
    border-radius: 4px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
}
.allergyText{
    font-size: 10px;
    color:#fff;
    text-align: center;
}
.addNumber{
    background-image: none;
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addBtn{
    background: linear-gradient(to right, #32A16E, #890503);
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addText{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

/*reser*/

.rvSelect{
    border-bottom:2px solid #32A16E;
    padding: 16px;
    margin: 8px 0;
    font-size: 24px;
    color:#32A16E;
    flex:1;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    transition: 0.2s box-shadow;

}
.rvNotSelect{
    border-bottom:2px solid #b7b7b7;
    padding: 16px;
    margin: 8px 0;
    font-size: 24px;
    color:#b7b7b7;
    font-weight: 700;
    flex:1;
    justify-content: center;
    align-items: center;
    transition: 0.2s box-shadow;
}
.rvSelect:hover, .rvNotSelect:hover{
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(218,220,230,.6)
}

.resLogo{
    height: 60px!important;
    width: 60px;
    object-fit: contain;
}
.resLogoDiv{
    padding: 16px 0px;
    margin-right: 16px;
}
.resSmall{
    font-size: 16px;
    font-weight: 600;
    color:#b7b7b7;
}
.resSmallIcon{
    font-size: 16px;
    font-weight: 600;
    color:#b7b7b7;
    margin-right: 4px;
}
.reservationContainer{
    /*padding: 0px;*/
    border: 1px solid #fff;
    padding: 16px;
    transition: .2s box-shadow;
    border-radius: 4px;

}
.reservationContainer:hover{
    border: 1px solid #ddd;
    transition:.2s box-shadow;
    box-shadow: 0 4px 4px 0 rgba(218,220,230,.6);
    cursor:pointer;
}
.resTitle{
    color:#b7b7b7;
    font-size: 18px;
    font-weight: 700;
    margin-right: 8px;
}
.resTitleSelect{
    color:#32A16E;
    font-size: 18px;
    font-weight: 700;
    margin-right: 8px;
    border-bottom: 1px solid #32A16E;
}
.reserButton{
    /*//background-image: linear-gradient(to right, #c94b4b , #4b134f);*/
    background:linear-gradient(to right, #32A16E, #890503);
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 48px;
    border-radius: 8px;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    transition: .2s box-shadow;
    width: fit-content;
    margin-left: auto;
}
.reserProcessButton{
    /*//background-image: linear-gradient(to right, #c94b4b , #4b134f);*/
    background-color: #aca5ae;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 48px;
    border-radius: 8px;
    color: #2d3032;
    font-size: 16px;
    font-weight: 700;
    transition: .2s box-shadow;
    width: fit-content;
    margin-left: auto;
}
.reserButton:hover{
    transition:.2s box-shadow;
    box-shadow: 0 4px 4px 0 rgba(218,220,230,.6);
    cursor:pointer;
}
.textBox{
    background-color: #e6e6e6 !important;
    color: #333333! important;
    font-size: 16px!important;
    font-weight: 700!important;
}
.commonFlexR{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 767px){
    .commonFlexR{
        flex-direction: row;
    }
    .rvSelect,.rvNotSelect {
        padding: 8px;
        margin: 4px 0;
        font-size: 16px;
    }
    .resTitle,.resTitleSelect{
        font-size: 16px;
    }
    .resSmall{
        font-size: 13px;
    }
}